<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">Reports</span>
      </h3>
    </div>
    <div class="panel-body">
      <div class="panel-heading pl-4 pt-10 pb-7">
        <h3>
          <span id="MainContent_lblReportName">Client Reports</span>
        </h3>
      </div>

      <div>
        <table cellspacing="0" style="width: 50%; border-collapse: collapse">
          <tbody>
            <tr>
              <td colspan="3"></td>
            </tr>
            <tr v-for="(item, index) in data" :key="index">
              <td @click="reportdetail($event)">
                <div class="rsmItem" style="cursor: pointer">
                  <a class="rsmLink" :title="item.first.tooltip">
                    <i class="fa fa-angle-right font-blue pr-3"></i>

                    {{ item.first.text }}</a
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      data: [
        {
          first: {
            text: "Client Authorization Expiraton",
            mark: true,
            tooltip: "Authorizations ending within 45 days",
          },
        },
        {
          first: {
            text: "Client Billing Summary",
            mark: false,
            tooltip:
              "A list of all client authorizations and hours used per month",
          },
        },
        {
          first: {
            text: "Client Billing Summary (PL1002)",
            mark: false,
            tooltip:
              "A list of all client authorizations and hours used per month for vendor PL1002",
          },
        },
        {
          first: {
            text: "Client Census Summary",
            mark: true,
            tooltip: "Number of clients by payor",
          },
          
        },
      ],
    };
  },
  computed: { ...mapGetters(["GetAuthenticatedUser"]) },
  mounted() {
    if (
      this.GetAuthenticatedUser.userType == "Super Admin" ||
      this.GetAuthenticatedUser.userType == "JFS" ||
      this.GetAuthenticatedUser.userType == "Admin Level 2"
    ) {
      this.data.push({
        first: {
          text: "Client Contact List",
          mark: false,
          tooltip: "List of all Clients and contact information",
        },
      });
      this.data.push({
        first: {
          text: "Employee Contact List",
          mark: true,
          tooltip: "List of all Employees and contact information",
        },
      });
    }
    this.data.push({
      first: {
        text: "Referal and Retention",
        mark: true,
        tooltip: "List of new referrals and terminations",
      },
    });
    this.data.push({
      first: {
        text: "Overtime Report",
        mark: true,
        tooltip: "Report For Overtime",
      },
    });
    this.data.push({
      first: {
        text: "Employee Inactivity Report",
        mark: true,
        tooltip: "Report For Inactive Employees",
      },
    });
    this.data.push({
      first: {
        text: "JFS Invoice Report",
        mark: true,
        tooltip: "Report For JFS Report",
      },
    });
    this.data.push({
      first: {
        text: "Expiration Report",
        mark: true,
        tooltip: "Expiration Report",
      },
    });
    this.data.push({
      first: {
        text: "Regional Center Invoicing",
        mark: true,
        tooltip: "RegionalCenterInvoicing",
      },
    });
  },
  methods: {
    reportdetail(obj) {
      if (obj.target.text == " Client Census Summary") {
        this.$router.push({
          name: "censussummary",
          params: {
            text: obj.target.text,
            title: obj.target.title,
            mark: obj.target.mark,
          },
        });
      } else if (obj.target.text == " Client Billing Summary") {
        this.$router.push({
          name: "clientbillingsummary",
          params: {
            text: obj.target.text,
            title: obj.target.title,
            mark: obj.target.mark,
          },
        });
      } else if (obj.target.text == " Client Billing Summary (PL1002)") {
        this.$router.push({
          name: "clientbillingsummary2",
          params: {
            text: obj.target.text,
            title: obj.target.title,
            mark: obj.target.mark,
          },
        });
      } else if (obj.target.text == " Client Contact List") {
        this.$router.push({
          name: "clientcontactsummary",
          params: {
            text: obj.target.text,
            title: obj.target.title,
            mark: obj.target.mark,
          },
        });
      } else if (obj.target.text == " Employee Contact List") {
        this.$router.push({
          name: "employeecontactsummary",
          params: {
            text: obj.target.text,
            title: obj.target.title,
            mark: obj.target.mark,
          },
        });
      } else if (obj.target.text == " Referal and Retention") {
        this.$router.push({
          name: "refferalsummary",
          params: {
            text: obj.target.text,
            title: obj.target.title,
            mark: obj.target.mark,
          },
        });
      } else if (obj.target.text == " Overtime Report") {
        this.$router.push({
          name: "overtimeReport",
          params: {
            text: obj.target.text,
            title: obj.target.title,
            mark: obj.target.mark,
          },
        });
      } else if (obj.target.text == " Employee Inactivity Report") {
        this.$router.push({
          name: "inactivityReport",
        });
      } else if (obj.target.text == " JFS Invoice Report") {
        this.$router.push({
          name: "JFSInvoice",
        });
      }
      else if (obj.target.text == " Expiration Report") {
        this.$router.push({
          name: "ExpirationSummary",
        });
      }  else if (obj.target.text == " Regional Center Invoicing") {
        this.$router.push({
          name: "RegionalCenterInvoicing",
        });
      } 
       else {
        this.$router.push({
          name: "detailreport",
          params: {
            text: obj.target.text,
            title: obj.target.title,
            mark: obj.target.mark,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.panel-body {
  background-color: #ffffff !important;
  padding: 10px;
  margin-top: 20px;
}
.spanstar {
  position: absolute;
  right: 10px;
  top: 10px;
}
.rsmItem a.rsmLink {
  background: #fff;
  padding: 7px;
  border: 1px solid #ddd;
  margin: 7px;
  display: block;
  box-shadow: 0 3px 0 #ddd;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  border-radius: 5px;
  position: relative;
}
.rsmItem a.rsmLink:hover {
  background: #f8f8f8;
  box-shadow: 0 3px 0 #0284d0;
}
.org-checked {
  color: orange;
}
.org-unchecked {
  color: lightgray;
}
</style>
